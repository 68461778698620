import React from 'react'
import styled from 'styled-components'

import Layout from '../layouts'
import SEO from '../components/seo'
import BG from '../components/bg'

const StyledFeatures = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  padding: 0 2rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding-top: 2rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

  @media (max-width: 960px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    margin-top: 0rem;
    padding-top: 1rem;
  }

  @media (max-width: 560px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const StyledSectionFlex = styled.div`
  border: 1px solid darkgray;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  // max-width: 960px;
  // margin-left: 5rem;
  margin-bottom: 2rem;
  @media (max-width: 1024px) {
    padding: 1rem;
    margin-top: 0rem;
    // flex-direction: ${({ wrapSmall }) => (!wrapSmall ? 'row' : 'column')};
  }
  @media (max-width: 960px) {
    padding: 1rem;
    margin-left: 0;
    margin-top: 0rem;
    width: 100%;
    // flex-direction: column;
    justify-content: space-between;
  }
  @media (max-width: 560px) {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    margin-left: 0;
    margin-top: 0rem;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  h1,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  h2 {
    max-width: 650px;
  }
  p {
    /* margin-bottom: 0.5rem; */
    max-width: 650px;
  }
`

const StyledSubSection = styled.div`
    width: 50%;
    margin-top: 1rem;
    @media (max-width: 960px) {
        width: 100%;
        margin-top: 0;
      }
`

const StyledSubBodyText = styled.div`
  font-size: 16px;
  margin-bottom: 1rem;
  display: inline-block;
`

const StyledSectionTitle = styled.h3`
  max-width: 960px;
  line-height: 140%;
  font-size: 32px;
  @media (max-width: 640px) {
    text-align: left;
  }
`

const StyledBodyText = styled.div`
  font-size: 16px;
  margin-bottom: 1rem;
`

const StyledExternalLink = styled.a`
  margin: 0;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  :focus {
    outline: 0;
    opacity: 0.9;
  }
  :hover {
    * {
      color: ${({ theme }) => theme.colors.link};
    }
  }
`

const StyledDetailCard = styled.div`
  margin-right: 25px;
  margin-bottom: 5px;
  display: flex;
  @media (max-width: 560px) {
    margin-right: 15px;
  }
`

const StyledIconImage = styled.img`
  margin-right: 12px;
  width: 80px;
  min-width: 80px;
  // max-width: 120px;
  margin-bottom: 1rem;
  background: none;
  border-radius: 12px;
`

const Features = props => {

    var vision = [
        {
          title: `Anonymous`
          , body: `Peer lending can be discreet. Every stakeholder is important to us, upholding confidentiality in our transactions.`
          , img: `/images/features/spectacles.png`
        }
        , {
            title: `Transparent`
          , body: `Public blockchain transactional records and leveraged AI machine trading`
          , img: `/images/features/magnifier.png`
        }
    ]

    var vision2 = [
        {
          title: `Universal`
          , body: `Peer lending does not discriminate and no lengthy document stacks and inefficient processes.`
          , img: `/images/features/people.png`
        }
        , {
            title: `Fair`
          , body: `No discrimination against borrowers, lenders and individual traders`
          , img: `/images/features/scalebalance.png`
        }
    ]

    var features = [
        {
          title: `Lending`
          , body: `Unpledged/ Pledged lending`
          , img: `/images/features/lending.png`
        }
        , {
            title: `ROI`
          , body: `Staking / liquidity mining / AMM / AI Trading / Sniper Bot`
          , img: `/images/features/roi.png`
        }
        , {
            title: `Hyper Yield`
          , body: `Yield farming through lending and AI leveraged trading`
          , img: `/images/features/yield.png`
        }
        , {
            title: `Control Policy`
          , body: `Token burn for deflationary`
          , img: `/images/features/coins.png`
        }
    ]

    var features2 = [
        {
          title: `Multi-Chain`
          , body: `Blockchain interoperability`
          , img: `/images/features/multichain.png`
        }
        , {
            title: `Anonymity`
          , body: `Anonymous lending and borrowing`
          , img: `/images/features/anonymous.png`
        }
        , {
            title: `Public Voting`
          , body: `Off-Chain voting for members`
          , img: `/images/features/voting.png`
        }
    ]

    return (
        <Layout path={props.location.pathname}>
            <BG />
            
            <SEO title="Features" path={props.location.pathname} />

            <StyledFeatures>
                <StyledSectionTitle>
                    The Future
                </StyledSectionTitle>
                <StyledBodyText>
                    To <span style={{ fontWeight: 'bold' }}>democratise</span> lending and <span style={{ fontWeight: 'bold' }}>liberalise</span> AI machine trading
                </StyledBodyText>
                <StyledBodyText>
                    We disrupt and open-up further possibilities in this untapped space, with a staunch belief that everyone has their rights to finance and chart their own financial endeavours in an innovative, transparent, and secure environment
                </StyledBodyText>
                <StyledSectionFlex>
                    <StyledSubSection>
                        {vision.map((feature, index) => {
                            return (
                                <SubDetail key={index} {...feature} />
                            )
                        })}
                    </StyledSubSection>
                    <StyledSubSection>
                        {vision2.map((feature, index) => {
                            return (
                                <SubDetail key={index} {...feature} />
                            )
                        })}
                    </StyledSubSection>
                </StyledSectionFlex>
                <StyledSectionTitle>
                    <a id='features' />Features
                </StyledSectionTitle>
                <StyledBodyText>
                    <span style={{ fontWeight: 'bold' }}>Streamline</span> Supply Chain, improve <span style={{ fontWeight: 'bold' }}>Traceability, Simplify</span> Trade & <span style={{ fontWeight: 'bold' }}>improve</span> Financial Transactions.
                </StyledBodyText>
                <StyledSectionFlex>
                    <StyledSubSection>
                        {features.map((feature, index) => {
                            return (
                                <SubDetail key={index} {...feature} />
                            )
                        })}
                    </StyledSubSection>
                    <StyledSubSection>
                        {features2.map((feature, index) => {
                            return (
                                <SubDetail key={index} {...feature} />
                            )
                        })}
                    </StyledSubSection>
                </StyledSectionFlex>
                <a id='earn' />
                <StyledSectionTitle>
                    How I Earn tokens from Rissotto
                </StyledSectionTitle>
                <StyledBodyText>
                    Rissotto has its own BEP-20 token, RSOT, which can be staked - or spread - across Pools, subscribe and/or participate in signal-based AI spot trading, sniper bot and lending.
                </StyledBodyText>
                <StyledSectionTitle>
                    What are Rissotto Exchange Fees Like?
                </StyledSectionTitle>
                <StyledBodyText>
                    Rissotto only charges a low 0.10% fee for all swaps, and unlike most centralized exchanges, this fee does not go to Rissotto but instead goes to the liquidity providers as their incentive for providing assets to the protocol.
                </StyledBodyText>
                <StyledBodyText>
                    Gas fees on Rissotto, meaning the fees required to send a transaction on the BSC chain, are a separate cost, and is equally low of no more than a few cents, depending on network congestion. There is no way around these fees and Rissotto has no control over them.
                </StyledBodyText>
                <StyledSectionTitle>
                    Best Features of Rissotto
                </StyledSectionTitle>
                <StyledBodyText>
                    As you might expect, one of the best features of <StyledExternalLink href='https://app.rissotto.finance'>Rissotto Exchange</StyledExternalLink> is its swap feature. You can easily swap any two Ethereum assets (BEP-20/ERC20) with just a few simple clicks. The swap feature is extremely convenient as it allows you to trade assets without registering for an exchange account or having your identity verified.
                </StyledBodyText>
                <StyledBodyText>
                    Liquidity pools are the backbone of <StyledExternalLink href='https://app.rissotto.finance'>Rissotto Exchange</StyledExternalLink> and one of the biggest draws for those looking to earn interest on their assets and AI machine trade. You can provide liquidity pools tokens by depositing an equal dollar amount of two BEP-20 tokens (BUSD and RSOT for example), and in return you receive a share of the fees earned by that pool, so the more swaps there are between this token pair, the more fees you will receive. Fees are paid in proportion to your contributions, so the more you have deposited the higher your returns. Machine trade wise, just choose between spot trading or sniper bot to decide what&apos;s your per trade limit and the platform will automatically execute your orders with real time calibration of stop lost based on the token profile.
                </StyledBodyText>
            </StyledFeatures>
        </Layout>
    )
  
}

const SubDetail = (feature) => {
  return (
    <StyledDetailCard>
        <StyledSubBodyText>
            <StyledIconImage src={feature.img} />
        </StyledSubBodyText>
        <StyledSubBodyText>
            <div style={{ fontWeight: 'bold' }}>{feature.title}</div>
            {feature.body}
        </StyledSubBodyText>      
    </StyledDetailCard>
  )
}

export default Features  